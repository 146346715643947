<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/groupbuy/list' }">拼团活动</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/groupbuy/list/edit' }"
          >查看拼团详情</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <el-row>
        <el-col :span="16"><h3>查看拼团详情</h3></el-col>
      </el-row>
      <el-form
        ref="form"
        :model="form"
        size="medium"
        :label-width="this.env.label_width"
        v-loading="loading"
      >
        <el-row>
          <el-col :span="16">
            <el-form-item label="拼团名称">
              <el-input
                disabled
                style="width: 100%"
                placeholder="请输入拼团活动名称"
                v-model="form.groupbuy_name"
              />
            </el-form-item>
            <el-form-item label="产品名">
              <span>{{ form.product_title }}</span>
            </el-form-item>
            <el-form-item label="人数/价格设置">
              <div v-for="(item, index) in format" :key="index">
                <el-input-number
                  size="small"
                  :step-strictly="true"
                  v-model="format[index]"
                  disabled
                  :min="1"
                  :max="10"
                ></el-input-number>
                <el-input
                  placeholder="拼团价格"
                  disabled
                  size="small"
                  style="width: 100px; margin-left: 24px"
                  v-model="formatPrice[index]"
                />
              </div>
            </el-form-item>
            <el-form-item label="发起规则">
              <el-input
                type="textarea"
                disabled
                :rows="3"
                :maxlength="200"
                show-word-limit
                v-model="form.initiate_rule"
              ></el-input>
            </el-form-item>
            <el-form-item label="参与规则">
              <el-input
                type="textarea"
                disabled
                :rows="3"
                :maxlength="200"
                show-word-limit
                v-model="form.participate_rule"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="medium" type="primary" @click="back">返回</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      format: [2, 3, 4], //规格设置
      form: {},
      loading: true,
      formatPrice: [0, 0, 0],
      order_dialog: false,
      joinProduct: {},
      page: 1,
      groupBuyDataList: [],
      chooseProductIdData: [], // 以选择可拼团产品id列表数据
      chooseProductNameData: [], // 以选择可拼团产品名字列表数据
      count: 0,
      groupBuyType: "group",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let groupbuy_uuid = this.$route.query.groupbuy_uuid;
      if (groupbuy_uuid !== undefined) {
        this.groupbuy_uuid = groupbuy_uuid;
        this.getinfo();
      } else {
        this.loading = false;
      }
    },
    // 拼团详情
    getinfo() {
      let postdata = {
        api_name: "marketing.groupbuy.getinfo",
        token: this.Tool.get_l_cache("token"),
        groupbuy_uuid: this.groupbuy_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.form = json.data;
          const sku = json.data.sku;
          let pricrData = [],
            formatData = [];
          for (let i in sku) {
            pricrData = pricrData.concat(sku[i].price);
            formatData = formatData.concat(sku[i].quota);
          }
          this.format = formatData;
          this.formatPrice = pricrData;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
